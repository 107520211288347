import _ from 'lodash';
import { reactive, computed } from '@vue/composition-api';
import HttpClient from './HttpClient';
import Router from './Router';

const routes = [
  { icon: 'mdi-view-dashboard-outline', name: 'Dashboard', path: 'dashboard' },
  { icon: 'mdi-briefcase-clock-outline', name: 'Offers', path: 'offers' },
  { icon: 'mdi-vector-link', name: 'Smartlinks', path: 'smartlinks' },
  {
    icon: 'mdi-chart-multiline',
    name: 'Reports',
    path: 'reports',
    children: [
      {
        icon: 'mdi-chart-multiline',
        name: 'Performance Report',
        path: 'reports',
        title: 'Performance Report',
      },
      {
        icon: 'mdi-chart-multiline',
        name: 'Conversion report',
        path: 'conversionReport',
        title: 'Conversion Report',
      },
    ],
  },
  { icon: 'mdi-account-cash-outline', name: 'Billing', path: 'billing' },
  { icon: 'mdi-account-supervisor-outline', name: 'Users', path: 'users' },
  { icon: 'mdi-lifebuoy', name: 'Support', path: 'support' },
  { icon: 'mdi-account-outline', name: 'My', path: 'my' },
];

// Enabled only for Dev/Staging
if (process.env.VUE_APP_STAGE === 'dev' || process.env.VUE_APP_STAGE === 'staging') {
  routes.splice(5, 0, { icon: 'mdi-web', name: 'Referral Program', path: 'referral' });
}

export const state = reactive({
  authentication: {
    credentials: {
      email: '',
      password: '',
      forgotten: false,
    },
    message: null,
    loading: false,
    success: false,
    error: false,
    recovery: false,
  },
  registration: {
    credentials: {
      account: '',
      email: '',
      code: '',
    },
    loading: false,
    success: false,
    error: false,
  },
  user: {},
  userName: computed(() => `${state.user.name}`),
  userNamePrefix: computed(() =>
    _.toUpper(
      `${state.user.name ? state.user.name.charAt(0) : ''}${
        state.user.name ? state.user.name.charAt(1) : ''
      }`
    )
  ),
  userEmail: computed(() => `${state.user.email}`),
  routes: computed(() => {
    const user = state.user;

    if (!user || !user.roles) return [];

    const validRoutes = [];

    for (const route of routes) {
      if (!route.children && user.roles[route.path]) {
        validRoutes.push(route);
      } else if (route.children) {
        const parentRoute = _.cloneDeep(route);
        parentRoute.children = route.children.filter((childRoute) => user.roles[childRoute.path]);
        if (parentRoute.children.length) {
          validRoutes.push(parentRoute);
        }
      }
    }
    return validRoutes;
  }),
  reducedRoutes: computed(() => {
    const user = state.user;

    if (!user || !user.roles) return [];

    const filteredRoutes = [];
    routes.forEach((route) => {
      if (route.children) {
        const children = route.children.filter((childRoute) => user.roles[childRoute.path]);
        filteredRoutes.push(...children);
      } else if (user.roles[route.path]) {
        filteredRoutes.push(route);
      }
    });

    return filteredRoutes;
  }),
});

export const actions = {
  async setup() {
    // first time app is executed -> pre-validation
    if (HttpClient.isAuthenticated()) {
      // authenticated -> restoring user data
      state.user = HttpClient.currentUser();
      if (_.isEmpty(this.allowedSections())) {
        // no allowed sections -> user kicked out
        await this.singOut();
      } else {
        // at least 1 section allowed -> redirect to 1st section
        Router.push(`/auth/${this.allowedSections()[0].path}`);
      }
    } else {
      // not authenticated -> redirect to login
      Router.push('/');
    }
    // setting router security
    Router.beforeEach((to, from, next) => {
      if (HttpClient.isAuthenticated()) {
        // authenticated -> get allowed sections
        if (to.path.startsWith('/auth/')) {
          // private section -> get section to access from path
          let sectionToAccess = to.path.split('/')[2];

          const user = state.user;
          const checkRoles = user?.roles[sectionToAccess] || false;
          if (
            this.allowedSections().filter((e) => e.path == sectionToAccess).length == 1 &&
            checkRoles
          ) {
            // permission ok -> continue
            next();
          } else {
            // no persmission -> stay in current section
            next(from.path);
          }
        } else {
          // not private section -> continue
          next();
        }
      } else {
        // not authenticated -> stay in current section
        next(from.path);
      }
    });
  },

  async singIn() {
    state.authentication.loading = true;
    try {
      let result = await HttpClient.singIn(state.authentication.credentials);
      if (_.has(result, 'recovery')) {
        state.authentication.recovery = result.recovery;
      } else {
        state.authentication.success = true;
        state.authentication.error = false;
        await this.setup();
      }
    } catch (error) {
      state.authentication.message = error.error;
      state.authentication.success = false;
      state.authentication.error = true;
    } finally {
      state.authentication.loading = false;
    }
  },

  async singOut() {
    await HttpClient.singOut();
    state.routes = [];
    window.location.href = '/';
  },

  async updateAttribute({ key, value }) {
    if (key === 'authentication.credentials.email') {
      value = value ? value.trim() : value;
    }
    _.set(state, `${key}`, value);
  },

  allowedSections() {
    let reducedRoutes = [];
    routes.forEach((route) => {
      if (route.children) {
        reducedRoutes.push(...route.children);
      } else {
        reducedRoutes.push(route);
      }
    });
    return state.user.admin
      ? reducedRoutes
      : reducedRoutes.filter((route) => state.user.roles && state.user.roles[route.path]);
  },
};
